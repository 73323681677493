<template>
<div class="top-btn" @mousedown="goTopEvt"></div>
</template>

<script>
export default {
    name: 'topBtn',
    // props: {
    //     locationData: Object
    // },
    computed: {
        // getStyle() {
        //     // const base = 1440;
        //     let style = '';
        //     const key = Object.keys(this.locationData);
        //     key.forEach(item => {
        //         style += `${item}: ${this.locationData[item]};`;
        //     });
        //     return style;
        // }
    },
    data(){
        return {
            
        }
    },
    methods: {
        goTopEvt() {
            document.getElementsByTagName('body')[0].scrollTop = 0;
        },
    }
}
</script>
<style lang="less" scoped>
.top-btn{
    width: 41px;
    height: 41px;
    background: #FFFFFF;
    // box-shadow: 0px 8px 45px 0px rgba(239,244,250,1);
    border-radius: 4px;
    border: 1px solid #E1E1E1;
    // position: absolute;
    position: fixed;
    z-index: 9999;
    cursor: pointer;
    bottom: 5%;
    right: 3%;
    
    &::after{
        content: "";
        width: 15px;
        height: 2px;
        background: #222222;
        border-radius: 1px;
        display: block;
        left: 50%;
        margin-left: -7.5px;
        position: absolute;
        top: 15px;
    }

    &::before{
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-top: 2px solid #222;
        border-right: 2px solid #222222;
        transform: rotate(-45deg);
        box-sizing: border-box;

        left: 50%;
        margin-left: -5px;
        position: absolute;
        top: 23px;
    }

    &:hover{
        border: none;
        background-color: #3292FF;
        box-shadow: 0px 10px 60px 0px rgba(239,244,250,1);
        border-radius: 4px;

        &::after{
            background-color: #fff;
        }
        &::before{
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
        }
    }
}
@media screen and (max-width: 1200px) {
    .top-btn{
        width: 41px;
        height: 41px;
        // top: 60%;
        // right: 5%;

        &::after{
            width: 15px;
            height: 2px;

            left: 50%;
            margin-left: -7.5px;
            position: absolute;
            top: 10px;
        }

        &::before{
            width: 10px;
            height: 10px;

            left: 50%;
            margin-left: -5px;
            position: absolute;
            top: 22px;
        }

        // &:hover{
        //     background-color: #3292FF;
        //     box-shadow: 0px 10px 60px 0px rgba(239,244,250,1);
        //     border-radius: 4px;

        //     &::after{
        //         background-color: #fff;
        //     }
        //     &::before{
        //         border-top: 2px solid #fff;
        //         border-right: 2px solid #fff;
        //     }
        // }
    }
}
</style>
